import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
    initialOpenState: true,
    items: [
      {
        label: 'Swap',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
      {
        label: 'Wrap/Unwrap VTRU',
        href: 'https://wrap.vitruveo.xyz',
      },
      {
        label: 'USDC Bridge',
        href:'https://usdc.vitruveo.xyz'
      },
      {
        label: 'Scope',
        href:'https://scope.vitruveo.xyz'
      }
    ],
  },
  // {
  //   label: 'Farms',
  //   icon: 'FarmIcon',
  //   href: 'https://coinbase.com',
  // },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: 'https://coinbase.com',
  // },
]

export default config
