import { ChainId, JSBI, Percent, Token, WETH } from '@pancakeswap-libs/sdk'

export const ROUTER_ADDRESS = '0x3295fd27D6e44529c51Ef05a5d16Ca17Fb9e10A8'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const DAI = new Token(ChainId.MAINNET, '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3', 18, 'DAI', 'Dai Stablecoin')
export const BUSD = new Token(ChainId.MAINNET, '0xe9e7cea3dedca5984780bafc599bd69add087d56', 18, 'BUSD', 'Binance USD')
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059ff775485246999027b3197955', 18, 'USDT', 'Tether USD')



export const TOKEN1 = new Token(ChainId.MAINNET, '0x3ccc3F22462cAe34766820894D04a40381201ef9', 18, 'wVTRU', 'Wrapped VTRU')
export const TOKEN2 = new Token(ChainId.MAINNET, '0xDECAF2f187Cb837a42D26FA364349Abc3e80Aa5D', 18, 'VTRO', 'VTRO')
export const TOKEN3 = new Token(ChainId.MAINNET, '0xbCfB3FCa16b12C7756CD6C24f1cC0AC0E38569CF', 6, 'USDC.pol', 'USDC.pol')
export const TOKEN4 = new Token(ChainId.MAINNET, '0x1C73Ee1b00F906f1866579A02750A8bAB682b493', 18, 'FLIPYA', 'FlipYa')

// export const TOKEN4 = new Token(ChainId.MAINNET, '0xa4bfCa3e1E09C52053d332605FFed56fcf62b4Ec', 18, 'MNA04', 'MNA04')
export const TOKEN5 = new Token(ChainId.MAINNET, '0xC20bFB46f637F688353EeA8dc27CF23668C703d2', 18, 'WETH', 'WETH')
export const TOKEN6 = new Token(ChainId.MAINNET, '0xb202409eA543d6b97194d3F64d8e1e578910Be4B', 18, 'HONCHO', 'Honcho')
// export const TOKEN7 = new Token(ChainId.MAINNET, '0xA013a8a03A9FC336faf55B8F68F35155Ca31266c', 18, 'VWBNB', 'VWBNB')
// export const TOKEN8 = new Token(ChainId.MAINNET, '0xfE54B0fFD2686a499d4450660038Fb20De9BF886', 18, 'WVUSDC', 'WVUSDC')
export const TOKEN9 = new Token(ChainId.MAINNET, '0x3593B75e2a849DFDACb7e1ADdA24cB836670532b', 18, 'USDT', 'WSDT')



// export const TOKEN5 = new Token(ChainId.MAINNET, '0xBcdEBCEa282e81f6bdcf2F0F607d587bAca486f7', 18, 'MNA03', 'MNA03')

export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
  18,
  'UST',
  'Wrapped UST Token'
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token'
)

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.BSCTESTNET]: [WETH[ChainId.BSCTESTNET]],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  // [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT, UST, ETH, TOKEN1, TOKEN2, TOKEN3, TOKEN5, TOKEN9],
  // [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET]],
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], TOKEN1, TOKEN2, TOKEN3],
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  // [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT,TOKEN1, TOKEN2, TOKEN3, TOKEN5, TOKEN9],
  // [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET]],
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], TOKEN1, TOKEN2, TOKEN3],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82', 18, 'CAKE', 'PancakeSwap Token'),
      new Token(ChainId.MAINNET, '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', 18, 'WBNB', 'Wrapped BNB'),
    ],
    [BUSD, USDT],
    [DAI, USDT],
  ],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 80
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
